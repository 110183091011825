import * as React from "react";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";

const DigitalMarketing = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Career Opportunities | Digital Marketing, Web Design, Web Development
        </title>

        <meta
          name="description"
          content="We are rapidly growing. Check out our available career opportunities in digital marketing, web design, web development, and sales."
        />
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:title"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta
          property="og:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          property="og:site_name"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta
          name="twitter:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          name="twitter:title"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>
      <div class="bg-white py-20 md:py-32">
        <div class="mx-auto px-7 md:max-w-5xl">
          <div class="text-center sm:text-left sm:flex sm:justify-start items-center">
            <div class="">
              <h1 class="text-2xl md:text-5xl font-bold text-black ">
                Digital Marketing Specialist
              </h1>
            </div>
          </div>
          <div class="sm:mt-5 sm:text-lg sm:max-w-5xl sm:mx-auto"></div>
          <h2 class="text-1xl md:text-1xl font-medium text-black ">
            <div class="pt-10 pb-0 ">
              ConsultFGC is a fast-growing digital marketing and advertising
              company composed of a small but skilled group of advertising and
              digital marketing gurus. The Digital Marketing Specialist will be
              part of our Client Projects team responsible for the execution of
              digital marketing, advertising and website projects for our
              clients. Salary commensurate with experience.
            </div>
          </h2>

          <h3 class="text-1xl md:text-1xl font-normal text-black ">
            <div class="pt-10 pb-0">
              The ideal candidate will have an understanding of and experience
              in digital marketing and advertising including SEO, PPC, SEM
              across platforms like Google, various social media channels, and
              programmatic software.
            </div>

            <p class="text-1xl md:text-1xl font-bold text-black ">
              <div class="pt-10 pb-4">Duties:</div>
            </p>

            <div class="ml-10">
              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Setting up client ad campaigns in Facebook, Instagram, Google
                  and our proprietary geofencing software, HYLOQ.
                </span>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Collaborating with our in-house development team on the
                  creatives (e.g. digital visual ads and written copy) needed
                  for client projects.
                </span>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Interacting with clients.
                </span>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Reviewing the work done prior to releasing it to the client to
                  ensure it is done correctly and to our high standards.
                </span>
              </div>
            </div>

            <div class="text-1xl md:text-1xl font-bold text-black ">
              <p class="pt-10 pb-4">Skills Required (in order of importance)</p>
            </div>

            <div class="ml-10">
              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  An understanding of digital marketing and advertising, the
                  various platforms like Google, social media channels, and
                  programmatic software.
                </span>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Professional-level copywriting skill for advertising and
                  website copy.
                </span>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Proficient in Microsoft Office (Outlook, Word, Excel, etc.)
                  and/or G-Suite equivalents.
                </span>
                <div class="px-3">
                  The ability to interact professionally with clients when
                  needed.
                </div>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  The ability to balance multiple priorities and projects, as
                  well as organize quickly & efficiently.
                </span>
              </div>

   
            </div>

            <div class="text-1xl md:text-1xl font-bold text-black ">
              <p class="pt-10 pb-4">Other Requirements</p>
            </div>
            <div class="ml-10">
              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Must have a car and be willing to work in our Dunedin office
                  location. You will be working IN-OFFICE to start with remote
                  work opportunities later.
                </span>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Bachelor’s degree in a related field, preferably Marketing,
                  Communications, or Advertising, or equivalent years of
                  experience.
                </span>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  2-3 Years experience working with Google, Meta Ads Manager,
                  and Instagram advertising.
                </span>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  A working knowledge of trends and forecasts for the marketing,
                  advertising, and tech industries, or a curiosity & desire to
                  learn more.
                </span>
              </div>
            </div>

            <div class="text-1xl md:text-1xl font-bold text-black ">
              <p class="pt-10 pb-4">Pluses</p>
            </div>
            <div class="ml-10">
              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  2-3 Years experience working with Google Ads and Google
                  Analytics. Google Certifications a plus
                </span>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Experience with Google Data Studio or other data visualization
                  tools is a plus.
                </span>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Experience with programmatic & CTV/OTT advertising is a plus.
                </span>
              </div>
            </div>

            <div class="pt-10 pb-0">Job Type: Full-time</div>

           

            <div class="pt-4 pb-0">Benefits:</div>

            <div class="ml-10">
              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Flexible work schedule
                </span>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Monthly Gas Stipend
                </span>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Quarterly bonus via Company Profit Sharing (after 90 days)
                </span>
              </div>

              <div>
                <span class="inline-flex items-center">
                  <svg
                    class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Opportunities to learn, grown, and gain new skills in the
                  marketing, advertising, and tech industries
                </span>
              </div>
            </div>

            <div class="pt-4 pb-0">
              Schedule:
              <div class="ml-10">
                <div>
                  <span class="inline-flex items-center">
                    <svg
                      class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx="4" cy="4" r="3" />
                    </svg>
                    Day Shift
                  </span>
                </div>

                <div>
                  <span class="inline-flex items-center">
                    <svg
                      class="-ml-0.5 mr-1.5 h-2 w-2 text-black"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx="4" cy="4" r="3" />
                    </svg>
                    Monday to Friday
                  </span>
                </div>
              </div>
            </div>
          </h3>

          <div class="mt-12 md:flex md:justify-start ">
            <div class="text-center">
              <a
                href="https://www.indeed.com/job/digital-marketing-specialist-99c23748ac5569cf"
                class="inline-flex text-sky-100 border-2 border-sky-600 bg-sky-600  font-medium rounded-lg text-xl px-20 md:px-28 py-4 hover:opacity-80 focus:outline-none"
              >
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DigitalMarketing;
